<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
import { useRouter } from 'vue-router';

const instanceStore = useInstanceStore()
const router = useRouter()
const { mobile } = useDisplay()
const { locale } = useI18n({ useScope: 'global' })
const showEulaDialog = ref(false)
const hasAcceptedEULA = ref(false)
const acceptedEula = ref(null)
const eulaLoading = ref(false)
const closeTimer = ref(10)

const isDataCollector = computed(isInDataCollectorMode)
const needToRefreshEula = computed(() => {
  if (instanceStore.getSenator && instanceStore.eulaRefreshRequired[instanceStore.getSenator.globalUniqueIdentifier] === true) return true
  if (!instanceStore.selectedInstanceId) return false
  return instanceStore.eulaRefreshRequired[instanceStore.selectedInstanceId] === true
})

async function acceptEula() {
  eulaLoading.value = true;
  if (!acceptedEula.value) return
  await BackendAPI.getOrCreateBackendAPIInstance(eulaTargetGUID.value).acceptEULA(acceptedEula.value)
  router.go(0)
  eulaLoading.value = false;
}

function canCloseTimerFunction() {
  if (closeTimer.value <= 0) return
  setTimeout(() => {
    closeTimer.value -= 1
    canCloseTimerFunction()
  }, 1000)
}

watch(needToRefreshEula, newValue => {
  showEulaDialog.value = newValue
}, { immediate: true })

const selectedInstanceId = computed(() => {
  return instanceStore.selectedInstanceId
})
const canCloseVersionWarning = computed(() => {
  return closeTimer.value <= 0
})
import { useSettingsStore } from '@/store/settings';
import { computed, onMounted, ref, watch } from 'vue';
import { useInstanceStore } from '@/store/backendInstances';
import { useBranchString } from '@/composables/version';
import { useDisplay } from 'vuetify';
import WelcomeDialogView from '@/components/WelcomeDialogView.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import { useMiscellaneousStore } from '@/store/miscellaneous';
import EulaCheckbox from '@/components/EulaCheckbox.vue';
import BackendAPI from '@/BackendAPI.js';
import { isInDataCollectorMode } from '@/composables/originTools.js';

const miscellaneousStore = useMiscellaneousStore()
const settingsStore = useSettingsStore()
const versionWarning = ref(false)
const versionWarningTitle = ref('')
const versionWarningText = ref('')
const versionWarningColor = ref('')
const showWelcomeDialog = ref(false)
const showLanguageDialog = ref(false)

const isUpdateAvailable = computed(() => {
  return miscellaneousStore.isUpdateAvailable
})

function update() {
  try {
    const updateHandler = miscellaneousStore.getUpdateHandler
    updateHandler()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const eulaTargetGUID = computed(() => {
  return instanceStore.selectedInstanceId ? instanceStore.selectedInstanceId : instanceStore.getSenator.globalUniqueIdentifier
})

const canShowWelcomeDialog = computed(() => {
  if (showLanguageDialog.value) return false
  if (notOnInstanceSelection.value) return false
  if(isDataCollector.value && instanceStore.globalUniqueIdentifiers.filter(globalUniqueIdentifier => instanceStore.instances[globalUniqueIdentifier].instanceType === 'data_collector').length !== 0) return false
  if (!isDataCollector.value && instanceStore.globalUniqueIdentifiersForBarns.length !== 0) return false
  return !settingsStore.genericInstancesEnabled;

})

const showGlobalAppBar = computed(() => {
  return !useInstanceStore().selectedInstanceId;
})

const notOnInstanceSelection = computed(() => {
  return router.currentRoute.value.name !== 'Home'
})


async function goToOverview() {
  return router.push({ name: 'Overview' })
}

async function goToSettings() {
  return router.push({ name: 'Settings' })
}

async function goBack() {
  return router.go(-1)
}

function setVersionWarning() {
  const value = useBranchString()
  versionWarningTitle.value = t('version.branches.' + value + '.title')
  versionWarningText.value = t('version.branches.' + value + '.text')
  switch (value) {
  case 'local':
    versionWarningColor.value = 'red'
    versionWarning.value = false
    useMiscellaneousStore().versionWarning = false
    break
  case 'dev':
    versionWarningColor.value = 'red'
    versionWarning.value = true
    useMiscellaneousStore().versionWarning = true
    break
  case 'staging':
    versionWarningColor.value = 'red'
    versionWarning.value = true
    useMiscellaneousStore().versionWarning = true
    break
  case 'production':
    versionWarning.value = false
    useMiscellaneousStore().versionWarning = false
    break
  default:
    versionWarning.value = false
    useMiscellaneousStore().versionWarning = false
  }
  closeTimer.value = 10
  canCloseTimerFunction()
}

function startVersionWarningTimer() {
  const min = 1000 * 20
  const max = 1000 * 60 * 10
  const timeout = Math.floor(Math.random() * (max - min + 1) + min)
  setTimeout(() => {
    setVersionWarning()
  }, timeout)
}

onMounted(() => {
  locale.value = settingsStore.language
  setVersionWarning()
  showLanguageDialog.value = !(settingsStore.languageSelected)
  showWelcomeDialog.value = true
})
</script>


<template>
  <v-theme-provider
    :theme="settingsStore.theme"
    :with-background="true"
  >
    <v-app>
      <v-dialog
        v-model="showLanguageDialog"
        :persistent="true"
        max-width="500"
        min-width="20vw"
      >
        <v-layout-card>
          <v-card-title>{{ $t('settings.select_language') }}</v-card-title>
          <v-card-text>
            <LanguageSelector />
          </v-card-text>
          <v-card-actions>
            <v-btn
              :block="true"
              color="success"
              variant="flat"
              class="rounded-pill"
              :text="$t('general_interface.buttons.confirm')"
              @click="settingsStore.languageSelected = true; showLanguageDialog = false"
            />
          </v-card-actions>
        </v-layout-card>
      </v-dialog>
      <v-dialog
        v-if="canShowWelcomeDialog"
        v-model="showWelcomeDialog"
        :persistent="true"
        width="auto"
      >
        <WelcomeDialogView
          @close="showWelcomeDialog = false"
        />
      </v-dialog>

      <v-app-bar
        v-if="versionWarning && !mobile"
        density="compact"
        location="top"
        class="my-0 py-0"
        :color="versionWarningColor"
      >
        <div class="w-100 rainbow">
          <v-row
            class="justify-space-between mx-2 my-0 py-0 align-center"
            :dense="true"
          >
            <v-col cols="auto">
              <v-chip class="font-weight-black text-capitalize">
                {{ versionWarningTitle }}
              </v-chip>
            </v-col>
            <v-col cols="auto">
              <span class="font-weight-black">{{ versionWarningText }}</span>
            </v-col>
            <v-col
              cols="auto"
              class="justify-end"
            >
              <v-btn
                v-if="!canCloseVersionWarning"
                :disabled="true"
                variant="flat"
                size="small"
                icon="true"
              >
                {{ closeTimer }}
              </v-btn>
              <v-btn
                v-if="canCloseVersionWarning"
                icon="mdi-close"
                size="small"
                @click="versionWarning = false; startVersionWarningTimer()"
              />
            </v-col>
          </v-row>
        </div>
      </v-app-bar>
      <v-app-bar v-if="showGlobalAppBar">
        <v-app-bar-nav-icon
          v-if="notOnInstanceSelection"
          icon="mdi-arrow-left"
          @click="goBack"
        />
        <v-app-bar-title v-if="!isDataCollector">
          {{ $t("general_interface.app_name") }}
        </v-app-bar-title>
        <v-app-bar-title v-if="isDataCollector">
          {{ $t("general_interface.app_name_data_collector") }}
        </v-app-bar-title>
        <v-btn
          v-if="mobile"
          :icon="true"
        >
          <v-icon icon="mdi-dots-vertical" />
          <v-menu activator="parent">
            <v-sheet>
              <v-list>
                <v-list-item
                  v-if="!isDataCollector"
                  :title="$t('router_elements.overview')"
                  @click="goToOverview"
                />
                <v-list-item
                  :title="$t('router_elements.settings')"
                  @click="goToSettings"
                />
              </v-list>
            </v-sheet>
          </v-menu>
        </v-btn>
        <div
          v-if="!mobile"
        >
          <v-btn
            v-if="!isDataCollector"
            class="rounded-pill"
            :text="$t('router_elements.overview')"
            @click="goToOverview"
          />
          <v-btn
            class="rounded-pill"
            :text="$t('router_elements.settings')"
            @click="goToSettings"
          />
        </div>
      </v-app-bar>
      <v-dialog
        v-if="mobile"
        v-model="versionWarning"
        :persistent="true"
      >
        <v-layout-card
          :color="versionWarningColor"
          class="rainbow"
        >
          <v-card-title>
            {{ versionWarningTitle }}
          </v-card-title>
          <v-card-text class="font-weight-black">
            {{ versionWarningText }}
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              v-if="!canCloseVersionWarning"
              :disabled="true"
              variant="flat"
              size="small"
              icon="true"
            >
              {{ closeTimer }}
            </v-btn>
            <v-btn
              v-if="canCloseVersionWarning"
              class="rounded-pill"
              variant="outlined"
              @click="versionWarning = false; startVersionWarningTimer()"
            >
              {{ $t("general_interface.buttons.close") }}
            </v-btn>
          </v-card-actions>
        </v-layout-card>
      </v-dialog>

      <v-snackbar
        v-if="isUpdateAvailable"
        color="primary"
        :timeout="-1"
        rounded="pill"
        :model-value="true"
        variant="elevated"
        :close-on-back="false"
        :close-on-content-click="false"
        :persistent="true"
      >
        <template #text>
          {{ $t("general_interface.update_available") }}
        </template>

        <template #actions>
          <v-btn
            class="rounded-pill"
            variant="outlined"
            @click="update"
          >
            {{ $t("general_interface.buttons.update") }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        v-if="needToRefreshEula"
        v-model="showEulaDialog"
        :persistent="true"
        width="auto"
        min-width="200px"
      >
        <v-layout-card :loading="eulaLoading">
          <v-card-title>
            {{ $t("eula.title") }}
          </v-card-title>
          <v-card-text>
            {{ $t("eula.text") }}
            <EulaCheckbox
              v-model:accepted="hasAcceptedEULA"
              v-model:version="acceptedEula"
              :global-unique-identifier="eulaTargetGUID"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :disabled="!hasAcceptedEULA"
              variant="elevated"
              color="success"
              class="rounded-pill"
              @click="acceptEula"
            >
              {{ $t("eula.accept") }}
            </v-btn>
          </v-card-actions>
        </v-layout-card>
      </v-dialog>
      <v-main class="background-gradient">
        <router-view :key="selectedInstanceId" />
      </v-main>
    </v-app>
  </v-theme-provider>
</template>

<style scoped>

@keyframes r {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 100% 0;
  }
}

.rainbow {
  background: -webkit-linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background: -moz-linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background: -o-linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background-size: 200% 200%;
  animation: r 15s linear infinite;
}

.background-gradient {
  background-image: linear-gradient(to top right, rgb(var(--v-theme-gradient01)), rgb(var(--v-theme-gradient02)), rgb(var(--v-theme-gradient03)));
}

</style>
